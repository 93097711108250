import * as React from "react";
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, Loading, Error } from 'react-admin';
import { Container, Header, Content, Footer } from 'rsuite';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import { Message, Notification } from 'rsuite';
import CsvDownload from 'react-json-to-csv';
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import PerVisitorsLoadDataView from './PerVisitorsLoadDataView';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    minHeight: 60,
    alignItems: 'flex-center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    alignSelf: 'flex-center',
    paddingLeft: theme.spacing(2),
  },
  container: {
    marginBottom: '40px',
  },
  header: {
    lineHeight: '60px',
  },
  content: {
    border: '0px solid black',
  },
  hint: {
    maxHeight: 20,
    maxWidth: 20,
  },
  table: {
    minWidth: 200,
  },
  downloadcsv: {
    float: 'right',
  },
  popper: {
    minWidth: 1000,
    minHeight: 500,
  },
}));

const EventBoothsWebinarView = ({dateRange, reportId}) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [reports, setReports] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(false);

  const [random, setRandom] = useState(Math.random());
  const reload = () => {
    const tempRandom = Math.random();
    if (tempRandom === random) {
      return reload();
    }
    setRandom(Math.random());
  };

  useEffect(() => {
  // This effect uses the `value` variable,
  // so it "depends on" `value`.
    setLoading(true);
    // console.log(dateRange);
    var dateString;
    if (dateRange) {
        dateString = `${dateRange[0]},${dateRange[1]}`;
    } else {
        dateString = '2020-07-07,2020-07-30';
    }
    const payload = {
      pagination: { page: 1, perPage: 500 },
      sort: { field: 'id', order: 'ASC' },
      dateRange: dateString,
      reportId: reportId,
    };
    dataProvider.getList('getEventEngagementPromisesDataLess', payload)
      .then(({ data }) => {
        // console.log(data);
        setReports(data);
        setLoading(false);
        // resolve();
      })
      .catch(error => {
        setError(error);
        setLoading(false);
        setReports([]);
        // reject();
      })
  }, [dateRange, reportId, random]);

  // if (loading) return <Loading loadingPrimary="loading Reposts" loadingSecondary=""/>;
  if (loading) return (
    <div className={classes.root}>
      <Skeleton />
      <Skeleton animation={false} />
      <Skeleton animation="wave" />
    </div>
  );
  if (error) return (
    <Message
      type="error"
      title="Error"
      description={
        <div>
          <p>
            {error.message}
            <br />
            Please try again. If the problem still persists, you can
            <a href="https://gist.github.com/angusluk/3417c09cfa65aba48b62b74dd8fc4bda"> contact us.</a>
          </p>
          <br />
          <Button variant="contained" onClick={() => reload()}>
            Retry
          </Button>
        </div>
      }
    />
  );
  if (!reports || reports === undefined || reports.success === false) return (
    <Message
      type="info"
      title="Informational"
      description={
        <p>
          <b>Hmm... the report is not ready yet.</b>
          <br />
          Data for this report should become available within 1-4 hours when processing completes.
          <br/>
          If it doesn't, please
           <a href="https://gist.github.com/angusluk/3417c09cfa65aba48b62b74dd8fc4bda"> Contact us.</a>
        </p>
      }
    />
  );
  if(reports.length <= 0) return (
    <Message
      type="info"
      title="Informational"
      description={
        <p>
          <b>There is no data for this report.</b>
        </p>
      }
    />
  );

  function showNotification(message){
    Notification["success"]({
      top: 80,
      title: "success",
      description: message
    });
  };

  const handleClick = (event) => {
    setOpen(open ? false : event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  return (
    <Container className={classes.container}>
      <Container className={classes.container}>
        <Header className={classes.header}>
          Booths
        </Header>
        <Content className={classes.content}>
        {
          reports.booths && reports.booths.length > 0 && (
          <TableContainer component={Paper}>
            <div className={classes.downloadcsv} onClick={() => {showNotification("exporting your booths report")}}>
              <a style={{color: "black"}} href={`${process.env.REACT_APP_VEXPO_REPORT_ENDPOINT}getEventEngagementPromisesCSV?dateRange=${dateRange[0]},${dateRange[1]}&reportId=${reportId}&dataType=booths`}><button>Export CSV</button></a>
            </div>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Label</TableCell>
                  <TableCell align="right">Number of views</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reports.booths.map(row => (
                  <TableRow key={row.booth_id} hover>
                    <TableCell component="th" scope="row">
                      {row.booth_name}
                    </TableCell>
                    <TableCell align="right">{row.numbers}</TableCell>
                    <TableCell align="right">
                      <PopupState variant="popover" popupId={row.booth_id}>
                      {popupState => (
                      <div>
                      <Button variant="contained" {...bindTrigger(popupState)}>
                        Visitors List
                      </Button>
                      <Popover
                        {...bindPopover(popupState)}
                          anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                        <div className={classes.popper}>
                          <PerVisitorsLoadDataView dateRange={dateRange} reportId={reportId} source={row.booth_name} dataType='booths' itemId={row.booth_id}/>
                        </div>
                      </Popover>
                      </div>
                      )}
                      </PopupState>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          )
        }
        </Content>
        <Footer></Footer>
      </Container>
      <Container className={classes.container}>
        <Header className={classes.header}>
          Webinars
        </Header>
        <Content className={classes.content}>
        {
          reports.webinars && reports.webinars.length > 0 && (
          <TableContainer component={Paper}>
            <div className={classes.downloadcsv} onClick={() => {showNotification("exporting your webinars report")}}>
              <a style={{color: "black"}} href={`${process.env.REACT_APP_VEXPO_REPORT_ENDPOINT}getEventEngagementPromisesCSV?dateRange=${dateRange[0]},${dateRange[1]}&reportId=${reportId}&dataType=webinars`}><button>Export CSV</button></a>
            </div>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Webinar Name</TableCell>
                  <TableCell align="right">Number of views</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reports.webinars.map(row => (
                  <TableRow key={row.webinar_id} hover>
                    <TableCell component="th" scope="row">
                      {row.webinar_name}
                    </TableCell>
                    <TableCell align="right">{row.numbers}</TableCell>
                    <TableCell align="right">
                      <PopupState variant="popover" popupId={row.webinar_id}>
                      {popupState => (
                      <div>
                      <Button variant="contained" {...bindTrigger(popupState)}>
                        Visitors List
                      </Button>
                      <Popover
                        {...bindPopover(popupState)}
                          anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                        <div className={classes.popper}>
                          <PerVisitorsLoadDataView dateRange={dateRange} reportId={reportId} source={row.webinar_id + '_' + row.webinar_name} dataType='webinars' itemId={row.webinar_id}/>
                        </div>
                      </Popover>
                      </div>
                      )}
                      </PopupState>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          )
        }
        </Content>
        <Footer></Footer>
      </Container>
      {
        reports.lives && reports.lives.length > 0 && (
      <Container className={classes.container}>
        <Header className={classes.header}>
          Live Webinars
        </Header>
        <Content className={classes.content}>
          <TableContainer component={Paper}>
            <div className={classes.downloadcsv} onClick={() => {showNotification("exporting your live webinars report")}}>
              <a style={{color: "black"}} href={`${process.env.REACT_APP_VEXPO_REPORT_ENDPOINT}getEventEngagementPromisesCSV?dateRange=${dateRange[0]},${dateRange[1]}&reportId=${reportId}&dataType=lives`}><button>Export CSV</button></a>
            </div>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Webinar Name</TableCell>
                  <TableCell align="right">Number of views</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reports.lives.map(row => (
                  <TableRow key={row.webinar_id} hover>
                    <TableCell component="th" scope="row">
                      {row.webinar_name}
                    </TableCell>
                    <TableCell align="right">{row.numbers}</TableCell>
                    <TableCell align="right">
                      <PopupState variant="popover" popupId={row.webinar_id}>
                      {popupState => (
                      <div>
                      <Button variant="contained" {...bindTrigger(popupState)}>
                        Visitors List
                      </Button>
                      <Popover
                        {...bindPopover(popupState)}
                          anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                        <div className={classes.popper}>
                          <PerVisitorsLoadDataView dateRange={dateRange} reportId={reportId} source={row.webinar_id + '_' + row.webinar_name} dataType='lives' itemId={row.webinar_id}/>
                        </div>
                      </Popover>
                      </div>
                      )}
                      </PopupState>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Content>
        <Footer></Footer>
      </Container>
      )
        }
    </Container>
  )
};

EventBoothsWebinarView.propTypes = {
  dateRange: PropTypes.array.isRequired,
  reportId: PropTypes.string.isRequired,
};


export default EventBoothsWebinarView;
