import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';

// const apiUrl = 'https://vexpo-report-api.web.app/';
// const apiUrl = 'https://vexpo-statistic-api.eventxtra.com/';
// const apiUrl = 'http://localhost:5001/vexpo-report/us-central1/';

const apiUrl = 'https://vexpo-statistic.eventx.cc/';
// const apiUrl = 'http://localhost:5001/vexpo-report/us-central1/';

const meetupApiUrl = 'https://meetup-api.eventxtra.com/api/';
// const meetupApiUrl = 'https://meetup-api.2vanx.com/api/';

const httpClient = fetchUtils.fetchJson;

export default {
    getMeetupList: (resource, params) => {
        const dateRange = params.dateRange;
        const reportId = params.reportId;
        const eventId = params.eventId;
        const salesId = params.salesId;
        const allField = params.allField;
        // console.log("event id is "+ eventId);
        const mode = params.mode;
        const query = {
            boothId: reportId,
            dateRange: dateRange,
            eventId: eventId,
            repId: salesId,
            mode: mode,
            allField: allField,
        };
        // const query = {};
        // console.log(query);
        const url = `${meetupApiUrl}${resource}?${stringify(query)}`;
        // console.log(url);
        return httpClient(url).then(({ headers, json }) => ({
            data: json,
            total: 1,
            // total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },

    getList: (resource, params) => {
        const dateRange = params.dateRange;
        const reportId = params.reportId;
        const filterPattern = params.filterPattern;
        const dataType = params.dataType;
        const itemId = params.itemId;
        const allField = params.allField;

        const query = {
            reportId: reportId,
            dateRange: dateRange,
            filterPattern: filterPattern,
            dataType: dataType,
            itemId: itemId,
            allField: allField,
        };
        // const query = {};
        // console.log(query);
        const url = `${apiUrl}${resource}?${stringify(query)}`;
        // console.log(url);
        var options = {};
        if (!options.headers) {
          options.headers = new Headers({ Accept: 'application/json' });
        }
        options.headers.set('Connection', 'keep-alive');
        options.headers.set('Cache-Control', 'no-cache');
        options.headers.set('Accept-Encoding', 'gzip, deflate, br');
        return httpClient(url, options).then(({ headers, json }) => ({
            data: json,
            total: 1,
            // total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },
};