import React from 'react';
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, Loading, Error } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import { Message, Notification, DateRangePicker } from 'rsuite';
import { Container, Header, Content, Footer } from 'rsuite';
import CsvDownload from 'react-json-to-csv';
import dateFns from "date-fns";
import dataProvider from './data-providers';

const useStyles = makeStyles({
  table: {
    minWidth: 200,
  },
  downloadcsv: {
    float: 'right',
  },
});

const CombineVisitorReport = (props) => {
  const reportId = props.match.params.id;
  const classes = useStyles();
  const [visitors, setVisitors] = useState();
  const [matomoVisitors, setMatomoVisitors] = useState();
  const [finalVisitors, setFinalVisitors] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  var totalActions = 0;
  const yesterday = () => {
    var date = new Date();
    date.setDate(date.getDate() - 1);
    return date;
  };
  const [dateRange, setDateRange] = React.useState(getDateRangeArray(yesterday(), yesterday()));

  function getDateRangeArray(startDate, endDate) {
    const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' });
    const [{ value: startMonth }, , { value: startDay }, , { value: startYear }] = dateTimeFormat.formatToParts(startDate);
    const startDayString = `${startYear}-${startMonth}-${startDay}`;
    const [{ value: endMonth }, , { value: endDay }, , { value: endYear }] = dateTimeFormat.formatToParts(endDate);
    const endDayString = `${endYear}-${endMonth}-${endDay}`;
    return [startDayString, endDayString];
  }

  useEffect(() => {
    var dateString;
    if (dateRange) {
      dateString = `${dateRange[0]},${dateRange[1]}`;
    } else {
      dateString = '2020-07-07,2020-07-30';
    }
    setLoading(true);
    setVisitors();
    setFinalVisitors();
    setMatomoVisitors();
    const payload = {
      pagination: { page: 1, perPage: 500 },
      sort: { field: 'username', order: 'ASC' },
      dateRange: dateString,
      reportId: reportId,
    };

    dataProvider.getList('getEventVisitorsPromises', payload)
      .then(({ data }) => {
        setVisitors(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
        setVisitors([]);
      })

    dataProvider.getList('matomo/eventVisitors', payload)
      .then(({ data }) => {
        // console.log(data);
        setMatomoVisitors(data);
        setLoading(false);
        // resolve();
      })
      .catch(error => {
        setError(error);
        setLoading(false);
        setMatomoVisitors([]);
        // reject();
      })
  }, [dateRange, reportId]);

  useEffect(() => {
    if (matomoVisitors && visitors) {
      const mergedData = [];

      visitors.forEach(({ user_token, numbers, email, first_name, last_name }) => {
        mergedData.push({
          user_token,
          email,
          first_name,
          last_name,
          actions: numbers,
        });
      });

      matomoVisitors.forEach((item) => {
        // 1. matomo id =  user_token
        // 2. matomo actions =  numbers
        // 3. check email
        const isDuplicate = !!visitors.find(({ email }) => email === item.email);

        if (!isDuplicate) {
          mergedData.push({
            user_token: item.id,
            actions: item.actions,
            email: item.email
          })
        }
        setFinalVisitors(mergedData);
      });
    }
  }, [visitors, matomoVisitors])

  // if (loading) return <Loading loadingPrimary="loading Visitors" loadingSecondary=""/>;
  if (loading) return (
    <div className={classes.root}>
      <Skeleton />
      <Skeleton animation={false} />
      <Skeleton animation="wave" />
    </div>
  );
  if (error) return (
    <Message
      type="error"
      title="Error"
      description={
        <p>
          {error.message}
          <br />
          Please try again later, if still happened, please
          <a href="https://gist.github.com/angusluk/3417c09cfa65aba48b62b74dd8fc4bda"> contact us.</a>
        </p>
      }
    />
  );
  if (!visitors || visitors === undefined || visitors.success === false) return (
    <Message
      type="info"
      title="Informational"
      description={
        <p>
          <b>Hmm... the visitors report is not ready yet.</b>
          <br />
          Data for this report should become available within 1-4 hours when processing completes.
          <br />
          If it doesn't, please
           <a href="https://gist.github.com/angusluk/3417c09cfa65aba48b62b74dd8fc4bda"> Contact us.</a>
        </p>
      }
    />
  );
  if (visitors.length <= 0) return (
    <Message
      type="info"
      title="Informational"
      description={
        <p>
          <b>There is no data for this report.</b>
        </p>
      }
    />
  );

  function showNotification(message) {
    Notification["success"]({
      top: 80,
      title: "success",
      description: message
    });
  };

  function displayDatetime(timestamp) {
    var t = new Date(timestamp);
    var date = t.getHours() + ":" + t.getMinutes() + ", " + t.toDateString() + ' TZ: ' + Intl.DateTimeFormat().resolvedOptions().timeZone;
    return date;
  }



  return (
    <>
      <Container className={classes.container}>
        <Header className={classes.header}>
          Firebase ({visitors?.length}), Matomo ({matomoVisitors?.length}), final ({finalVisitors?.length})
        </Header>
        <DateRangePicker ranges={[{
          label: 'Yesterday',
          value: [dateFns.addDays(new Date(), -1), dateFns.addDays(new Date(), -1)]
        }, {
          label: 'Last 2 days',
          value: [dateFns.addDays(new Date(), -2), dateFns.addDays(new Date(), -1)]
        }, {
          label: 'Last 7 days',
          value: [dateFns.subDays(new Date(), 6), new Date()]
        }]}
          disabledDate={date => dateFns.isAfter(date, yesterday())} defaultValue={[new Date(dateRange[0]), new Date(dateRange[1])]} onChange={value => {
            setDateRange(getDateRangeArray(value[0], value[1]));
          }}
        />
        {/* final */}
        {finalVisitors &&
          <div className={classes.downloadcsv} onClick={() => { showNotification("exporting your visitors report") }}>
            <CsvDownload data={finalVisitors} filename={`report_event_unique visitor combined_list_${dateRange[0]}-${dateRange[1]}.csv`}>Export CSV</CsvDownload>
          </div>
        }
        <Content className={classes.content}>
          <TableContainer component={Paper}>
            <h3>conbined datas</h3>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>User Token or Id</TableCell>
                  <TableCell>E-mail</TableCell>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {finalVisitors?.map((row, idx) => (
                  <TableRow key={row.user_token} hover>
                    <TableCell>
                      {row.user_token}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.email}
                    </TableCell>
                    <TableCell align="left" id={`${idx}_${row.first_name}_${row.user_token}`}>{row.first_name}</TableCell>
                    <TableCell align="left" id={`${idx}_${row.last_name}_${row.user_token}`}>{row.last_name}</TableCell>
                    <TableCell align="right" id={`${idx}_${row.numbers}`}>{row.actions}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Content>
        {/* realtime db */}
        <Content className={classes.content}>
          <TableContainer component={Paper}>
            <h3>realtimedb Visitors</h3>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>E-mail</TableCell>
                  <TableCell>First Name</TableCell>
                  <TableCell>Last Name</TableCell>
                  <TableCell align="right">Actions</TableCell>
                  <TableCell align="right">First Action At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {visitors.map(row => (
                  <TableRow key={row.user_token} hover>
                    <TableCell component="th" scope="row">
                      {row.email}
                    </TableCell>
                    <TableCell align="left" id={row.first_name + row.user_token}>{row.first_name}</TableCell>
                    <TableCell align="left" id={row.last_name + row.user_token}>{row.last_name}</TableCell>
                    <TableCell align="right" id={totalActions += row.numbers}>{row.numbers}</TableCell>
                    <TableCell align="right" id={row.first_action_at}>{displayDatetime(row.first_action_at)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Content>
        {/* matomo db */}
        <Content className={classes.content}>
          <TableContainer component={Paper}>
            <h3>matomoVisitors</h3>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>E-mail</TableCell>
                  <TableCell align="right">Visits</TableCell>
                  <TableCell align="right">Actions</TableCell>
                  <TableCell align="right">Screen Time (sec)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {matomoVisitors?.map((row, idx) => (
                  <TableRow key={row.id} hover>
                    <TableCell >
                      {row.id}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.email}
                    </TableCell>
                    <TableCell align="right" id={`${idx}_${row.visits}`}>{row.visits}</TableCell>
                    <TableCell align="right" id={`${idx}_${row.actions}`}>{row.actions}</TableCell>
                    <TableCell align="right" id={`${idx}_${row.screen_time}`}>{row.screen_time}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Content>
        <Footer></Footer>
      </Container>
    </>
  )
};

export default CombineVisitorReport;
