import * as React from "react";
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Container, Header, Content, Footer } from 'rsuite';
import { makeStyles } from '@material-ui/core/styles';
import EventBoothsView from './EventBoothsView';
import EventWebinarsView from './EventWebinarsView';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    minHeight: 60,
    alignItems: 'flex-center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    alignSelf: 'flex-center',
    paddingLeft: theme.spacing(2),
  },
  container: {
    marginBottom: '40px',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  header: {
    lineHeight: '60px',
  },
  content: {
    border: '0px solid black',
  },
  hint: {
    maxHeight: 20,
    maxWidth: 20,
  },
}));

const EventBoothsWebinarView = ({dateRange, reportId}) => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Container className={classes.container}>
        <Header className={classes.header}>
          Booths
        </Header>
        <Content className={classes.content}>
          <EventBoothsView dateRange={dateRange} reportId={reportId}/>
        </Content>
        <Footer></Footer>
      </Container>
      <Container className={classes.container}>
        <Header className={classes.header}>
          Webinars
        </Header>
        <Content className={classes.content}>
          <EventWebinarsView dateRange={dateRange} reportId={reportId}/>
        </Content>
        <Footer></Footer>
      </Container>
    </Container>
  )
};

EventBoothsWebinarView.propTypes = {
  dateRange: PropTypes.array.isRequired,
  reportId: PropTypes.string.isRequired,
};


export default EventBoothsWebinarView;
