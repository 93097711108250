import * as React from "react";
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, Loading, Error } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import { Message, Notification, Header } from 'rsuite';
import CsvDownload from 'react-json-to-csv';
// import { Container, , Content, Footer, Sidebar } from 'rsuite';
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import ReedPerVisitorsView from './ReedPerVisitorsView';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'

const useStyles = makeStyles({
  table: {
    minWidth: 200,
  },
  downloadcsv: {
    float: 'right',
  },
  container: {
    marginBottom: '40px',
  },
  header: {
    lineHeight: '60px',
  },
});


const ReportsListView = ({dateRange, reportId, allField}) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [reports, setReports] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(false);

  const [random, setRandom] = useState(Math.random());
  const reload = () => {
    const tempRandom = Math.random();
    if (tempRandom === random) {
      return reload();
    }
    setRandom(Math.random());
  };

  useEffect(() => {
  // This effect uses the `value` variable,
  // so it "depends on" `value`.
    setLoading(true);
    // console.log(dateRange);
    var dateString;
    if (dateRange) {
        dateString = `${dateRange[0]},${dateRange[1]}`;
    } else {
        dateString = '2020-07-07,2020-07-30';
    }
    const payload = {
      pagination: { page: 1, perPage: 500 },
      sort: { field: 'id', order: 'ASC' },
      allField, allField,
      dateRange: dateString,
      reportId: reportId,
    };
    dataProvider.getList('getBoothOverviewPromises', payload)
      .then(({ data }) => {
        // console.log(data);
        setReports(data);
        setLoading(false);
        // resolve();
      })
      .catch(error => {
        setError(error);
        setLoading(false);
        setReports([]);
        // reject();
      })
  }, [dateRange, reportId, random]);

  // if (loading) return <Loading loadingPrimary="loading Reposts" loadingSecondary=""/>;
  if (loading) return (
    <div className={classes.root}>
      <Skeleton />
      <Skeleton animation={false} />
      <Skeleton animation="wave" />
    </div>
  );
  if (error) return (
    <Message
      type="error"
      title="Error"
      description={
        <div>
          <p>
            {error.message}
            <br />
            Please try again. If the problem still persists, you can
            <a href="https://gist.github.com/angusluk/3417c09cfa65aba48b62b74dd8fc4bda"> contact us.</a>
          </p>
          <br />
          <Button variant="contained" onClick={() => reload()}>
            Retry
          </Button>
        </div>
      }
    />
  );
  if (!reports || reports === undefined || reports.success === false) return (
    <Message
      type="info"
      title="Informational"
      description={
        <p>
          <b>Hmm... the report is not ready yet.</b>
          <br />
          Data for this report should become available within 1-4 hours when processing completes.
          <br/>
          If it doesn't, please
           <a href="https://gist.github.com/angusluk/3417c09cfa65aba48b62b74dd8fc4bda"> Contact us.</a>
        </p>
      }
    />
  );
  if(reports.length <= 0 || !reports.overview || reports.overview.length <= 0) return (
    <Message
      type="info"
      title="Informational"
      description={
        <p>
          <b>There is no data for this report.</b>
        </p>
      }
    />
  );
  let download_overview = JSON.parse(JSON.stringify(reports.overview));
  if (download_overview) {
    for (var i = 0; i < download_overview.length; i++) {
      delete download_overview[i].users;
    }
  }

  let download_banner = JSON.parse(JSON.stringify(reports.banner));
  if (download_banner) {
    for (var i = 0; i < download_banner.length; i++) {
      delete download_banner[i].users;
    }
  }

  let download_tabs = JSON.parse(JSON.stringify(reports.tabs));
  if (download_tabs) {
    for (var i = 0; i < download_tabs.length; i++) {
      delete download_tabs[i].users;
    }
  }

  let download_files = JSON.parse(JSON.stringify(reports.files));
  if (download_files) {
    for (var i = 0; i < download_files.length; i++) {
      delete download_files[i].users;
    }
  }

  function showNotification(message){
    Notification["success"]({
      top: 80,
      title: "success",
      description: message
    });
  };

  const handleClick = (event) => {
    setOpen(open ? false : event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  return (
    <>
    {
      reports.overview && reports.overview.length > 0 && (
      <div className={classes.container}>
      <TableContainer component={Paper}>
        <div className={classes.downloadcsv} onClick={() => {showNotification("exporting your booth report")}}>
          <CsvDownload data={download_overview} filename={`report_overview_${dateRange[0]}-${dateRange[1]}.csv`}>Export CSV</CsvDownload>
        </div>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Label</TableCell>
              <TableCell align="right">Number</TableCell>
              <TableCell align="right">Unique Visitor</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {reports.overview.map(row => (
            <TableRow key={row.id} hover>
              <TableCell component="th" scope="row">
                {row.label}
              </TableCell>
              <TableCell align="right">{row.visits}</TableCell>
              <TableCell align="right">{row.visitors}</TableCell>
              <TableCell align="right">
                <PopupState variant="popover" popupId={row.booth_id}>
                  {popupState => (
                    <div>
                      <Button variant="contained" {...bindTrigger(popupState)}>
                        Visitors List
                      </Button>
                      <Popover
                        {...bindPopover(popupState)}
                          anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                        <div className={classes.popper}>
                          <ReedPerVisitorsView dateRange={dateRange} reportId={reportId} users={row.users} source={row.label}/>
                        </div>
                      </Popover>
                    </div>
                  )}
                </PopupState>
              </TableCell>
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
      )
    }
    {
      reports.banner && reports.banner.length > 0 && (
      <div className={classes.container}>
      <Header className={classes.header}>
        Banner Clicks Distribution
      </Header>
      <TableContainer component={Paper}>
        <div className={classes.downloadcsv} onClick={() => {showNotification("exporting your booth bannner report")}}>
          <CsvDownload data={download_banner} filename={`report_banner_${dateRange[0]}-${dateRange[1]}.csv`}>Export CSV</CsvDownload>
        </div>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Label</TableCell>
              <TableCell align="right">Number of Click</TableCell>
              <TableCell align="right">Unique Visitor</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {reports.banner.map(row => (
            <TableRow key={row.id} hover>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.numbers}</TableCell>
              <TableCell align="right">{row.users.length}</TableCell>
              <TableCell align="right">
                <PopupState variant="popover" popupId={row.booth_id}>
                  {popupState => (
                    <div>
                      <Button variant="contained" {...bindTrigger(popupState)}>
                        Visitors List
                      </Button>
                      <Popover
                        {...bindPopover(popupState)}
                          anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                        <div className={classes.popper}>
                          <ReedPerVisitorsView dateRange={dateRange} reportId={reportId} users={row.users} source={row.name}/>
                        </div>
                      </Popover>
                    </div>
                  )}
                </PopupState>
              </TableCell>
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
      )
    }
    {
      reports.tabs && reports.tabs.length > 0 && (
      <div className={classes.container}>
      <Header className={classes.header}>
        Tab Clicks Distribution
      </Header>
      <TableContainer component={Paper}>
        <div className={classes.downloadcsv} onClick={() => {showNotification("exporting your booth tabs report")}}>
          <CsvDownload data={download_tabs} filename={`report_tabs_${dateRange[0]}-${dateRange[1]}.csv`}>Export CSV</CsvDownload>
        </div>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Label</TableCell>
              <TableCell align="right">Number of Click</TableCell>
              <TableCell align="right">Unique Visitor</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {reports.tabs.map(row => (
            <TableRow key={row.id} hover>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.numbers}</TableCell>
              <TableCell align="right">{row.users.length}</TableCell>
              <TableCell align="right">
                <PopupState variant="popover" popupId={row.booth_id}>
                  {popupState => (
                    <div>
                      <Button variant="contained" {...bindTrigger(popupState)}>
                        Visitors List
                      </Button>
                      <Popover
                        {...bindPopover(popupState)}
                          anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                        <div className={classes.popper}>
                          <ReedPerVisitorsView dateRange={dateRange} reportId={reportId} users={row.users} source={row.name}/>
                        </div>
                      </Popover>
                    </div>
                  )}
                </PopupState>
              </TableCell>
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
      )
    }
    {
      reports.files && reports.files.length > 0 && (
      <div className={classes.container}>
      <Header className={classes.header}>
        Files View and Bookmark
      </Header>
      <TableContainer component={Paper}>
        <div className={classes.downloadcsv} onClick={() => {showNotification("exporting your booth files report")}}>
          <CsvDownload data={download_files} filename={`report_files_${dateRange[0]}-${dateRange[1]}.csv`}>Export CSV</CsvDownload>
        </div>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>File Name</TableCell>
              <TableCell align="right">Number of Views</TableCell>
              <TableCell align="right">Unique Visitor</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {reports.files.map(row => (
            <TableRow key={row.id} hover>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.numbers}</TableCell>
              <TableCell align="right">{row.users.length}</TableCell>
              <TableCell align="right">
                <PopupState variant="popover" popupId={row.booth_id}>
                  {popupState => (
                    <div>
                      <Button variant="contained" {...bindTrigger(popupState)}>
                        Visitors List
                      </Button>
                      <Popover
                        {...bindPopover(popupState)}
                          anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                        >
                        <div className={classes.popper}>
                          <ReedPerVisitorsView dateRange={dateRange} reportId={reportId} users={row.users} source={row.name}/>
                        </div>
                      </Popover>
                    </div>
                  )}
                </PopupState>
              </TableCell>
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
      )
    }
    </>
  )
};

ReportsListView.propTypes = {
  dateRange: PropTypes.array.isRequired,
  reportId: PropTypes.string.isRequired,
  allField: PropTypes.bool,
};

export default ReportsListView;

