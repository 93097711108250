import React from 'react';
import { useParams } from "react-router-dom";
import styled from 'styled-components';
import BoothVisitors from './components/BoothVisitors';

const Wrapper = styled.div`
  color: #666;
  background: #f5f5f5;
  min-height: 100vh;
`;

const Header = styled.div`
  border-bottom: 1px solid #666;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background: #fff;
`;

const Title = styled.h5`
`;

const Content = styled.div`
  padding: 16px;
`;

function BoothDashboard() {
  const { id } = useParams();
  const domain = id?.split('_')?.join('.');

  return (
    <Wrapper>
      <Header>
        <Title>Dashboard</Title>
      </Header>
      <Content>
        <BoothVisitors />
      </Content>
    </Wrapper>
  );
}

export default BoothDashboard;

