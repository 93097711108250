import React from 'react';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
`;

const CenteredContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%,-50%);
`;

const CircleIndicator = () => (
  <Wrapper>
    <CenteredContainer>
      <CircularProgress />
    </CenteredContainer>
  </Wrapper>
);

export default CircleIndicator;
