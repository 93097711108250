import React from 'react';
import styled from 'styled-components';
import ActiveVisitors from './components/ActiveVisitors';
import LoginVisitors from './components/LoginVisitors';
const Wrapper = styled.div`
  color: #666;
  background: #f5f5f5;
  min-height: 100vh;
`;

const Header = styled.div`
  border-bottom: 1px solid #666;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background: #fff;
`;

const Title = styled.h5`
`;

const Content = styled.div``;

function EventRealtimeDashboard() {
  return (
    <Wrapper>
      <Header>
        <Title>Dashboard</Title>
      </Header>
      <Content>
        {/* Active Visitors &  Visitor Chart */}
        {/*<ActiveVisitors/>*/}
        {/* Login Visitors & Login History */}
        <LoginVisitors />
      </Content>
    </Wrapper>
  );
}

export default EventRealtimeDashboard;

