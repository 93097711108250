// worker.js
/* eslint-disable */
const workercode = () => {
	var loginNumber = 0;
	var total_visitors = [];
	var total_visitors_list = [];
	var latest_visitors_list = [];

	function sleep(milliseconds) {
	  const date = Date.now();
	  let currentDate = null;
	  do {
	    currentDate = Date.now();
	  } while (currentDate - date < milliseconds);
	}
	function organizerReportRowFormat(key, childData) {
	  return {user_token: key, email: childData.user_email, last_changed: childData.last_changed};
	}
	
    self.onmessage = function(e) {
        var type = e.data[0];
        switch (type) {
          case 'init':
          loginNumber = e.data[1];
		  latest_visitors_list = e.data[2];
		  total_visitors = e.data[3];
          break;
          case 'receiving':
          default:
            var key = e.data[1];
		    var childData = e.data[2];
			if (total_visitors.includes(key) === false) {
		      total_visitors.push(key);
	          loginNumber = total_visitors.length;
            }
			latest_visitors_list.unshift(organizerReportRowFormat(key, childData));
			sleep(5);
		    this.postMessage([loginNumber, latest_visitors_list.slice(0, 30), total_visitors]);
          break;
        }
    }
};

let code = workercode.toString();
code = code.substring(code.indexOf("{")+1, code.lastIndexOf("}"));

const blob = new Blob([code], {type: "application/javascript"});
const worker_script = URL.createObjectURL(blob);

module.exports = worker_script;
