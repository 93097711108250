import * as React from "react";
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, Loading, Error } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import { Message, Notification } from 'rsuite';
import { Container, Header, Content, Footer } from 'rsuite';
import CsvDownload from 'react-json-to-csv';

const useStyles = makeStyles({
  table: {
    minWidth: 200,
  },
  downloadcsv: {
    float: 'right',
  },
});

const EventVisitorsView = ({dateRange, reportId}) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [visitors, setVisitors] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  var totalVisits = 0;
  var totalActions = 0;
  var totalScreenTime = 0;

  useEffect(() => {
    // console.log(dataProvider);
    var dateString;
    if (dateRange) {
        dateString = `${dateRange[0]},${dateRange[1]}`;
    } else {
        dateString = '2020-07-07,2020-07-30';
    }
    setLoading(true);
    const payload = {
       pagination: { page: 1, perPage: 500 },
       sort: { field: 'username', order: 'ASC' },
       dateRange: dateString,
       reportId: reportId,
    };

    dataProvider.getList('matomo/eventVisitors', payload)
      .then(({ data }) => {
        // console.log(data);
        setVisitors(data);
        setLoading(false);
        // resolve();
      })
      .catch(error => {
        setError(error);
        setLoading(false);
        setVisitors([]);
        // reject();
      })
    }, [dateRange, reportId]);

  // if (loading) return <Loading loadingPrimary="loading Visitors" loadingSecondary=""/>;
  if (loading) return (
    <div className={classes.root}>
      <Skeleton />
      <Skeleton animation={false} />
      <Skeleton animation="wave" />
    </div>
  );
  if (error) return (
    <Message
      type="error"
      title="Error"
      description={
        <p>
          {error.message}
          <br />
          Please try again later, if still happened, please
          <a href="https://gist.github.com/angusluk/3417c09cfa65aba48b62b74dd8fc4bda"> contact us.</a>
        </p>
      }
    />
  );
  if (!visitors || visitors === undefined || visitors.success === false) return (
    <Message
      type="info"
      title="Informational"
      description={
        <p>
          <b>Hmm... the visitors report is not ready yet.</b>
          <br />
          Data for this report should become available within 1-4 hours when processing completes.
          <br/>
          If it doesn't, please
           <a href="https://gist.github.com/angusluk/3417c09cfa65aba48b62b74dd8fc4bda"> Contact us.</a>
        </p>
      }
    />
  );
  if(visitors.length <= 0) return (
    <Message
      type="info"
      title="Informational"
      description={
        <p>
          <b>There is no data for this report.</b>
        </p>
      }
    />
  );

  function showNotification(message){
    Notification["success"]({
      top: 80,
      title: "success",
      description: message
    });
  };

  return (
    <>
    <Container className={classes.container}>
          <Header className={classes.header}>
          Unique Visitors List ({visitors.length})
          </Header>
          <Content className={classes.content}>
    <TableContainer component={Paper}>
      <div className={classes.downloadcsv} onClick={() => {showNotification("exporting your visitors report")}}>
        <CsvDownload data={visitors} filename={`report_event_unique visitor list_${dateRange[0]}-${dateRange[1]}.csv`}>Export CSV</CsvDownload>
      </div>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>E-mail</TableCell>
            <TableCell align="right">Visits</TableCell>
            <TableCell align="right">Actions</TableCell>
            <TableCell align="right">Screen Time (sec)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {visitors.map(row => (
            <TableRow key={row.id} hover>
              <TableCell component="th" scope="row">
                {row.email}
              </TableCell>
              <TableCell align="right" id={totalVisits += row.visits}>{row.visits}</TableCell>
              <TableCell align="right" id={totalActions += row.actions}>{row.actions}</TableCell>
              <TableCell align="right" id={totalScreenTime += row.screen_time}>{row.screen_time}</TableCell>
            </TableRow>
          ))}
          <TableRow key={'total'} hover>
            <TableCell component="th" scope="row">
              Total
            </TableCell>
            <TableCell align="right">{totalVisits}</TableCell>
            <TableCell align="right">{totalActions}</TableCell>
            <TableCell align="right">{totalScreenTime}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    </Content>
          <Footer></Footer>
        </Container>
    </>
  )
};

EventVisitorsView.propTypes = {
  dateRange: PropTypes.array.isRequired,
  reportId: PropTypes.string.isRequired,
};


export default EventVisitorsView;
