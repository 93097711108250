import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip, Tabs, Tab, Box } from '@material-ui/core';
import { Container, Header, Content, Footer, Sidebar } from 'rsuite';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import { Provider } from 'react-redux';
import { AuthContext, DataProviderContext, TranslationProvider, Resource } from 'react-admin';
import dataProvider from './data-providers';
import createAdminStore from './createAdminStore';
import { createHashHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import ReportsListView from './realtime/components/ReportsListView';
import VisitorsListView from './realtime/components/VisitorsListView';
import BoothMeetupsView from './realtime/components/BoothMeetupsView';
import { DateRangePicker, Notification } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';

import ExportIcon from '@material-ui/icons/GetAppSharp';
import HelpIcon from '@material-ui/icons/Help';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CsvDownload from 'react-json-to-csv';
import queryString from 'query-string';
import dateFns from "date-fns";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#cf2f2f',
      backgroundColor: '#eeeeee',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    zIndex: 5,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    minHeight: 60,
    alignItems: 'flex-center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    alignSelf: 'flex-center',
    paddingLeft: theme.spacing(2),
  },
  container: {
    marginBottom: '40px',
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  header: {
    lineHeight: '60px',
  },
  content: {
    border: '0px solid black',
  },
  hint: {
    maxHeight: 20,
    maxWidth: 20,
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const history = createHashHistory();
const authProvider = () => Promise.resolve();
const {
  afterToday,
} = DateRangePicker;

export default function BoothReport(props) {
  const reportId = props.match.params.id;
  const meetupId = queryString.parse(window.location.search)['mid'];
  const allField = queryString.parse(window.location.search, {parseBooleans: true})['result'];
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const yesterday = () => {
    var date = new Date();
    date.setDate(date.getDate() - 1);
    return date;
  };
  const [dateRange, setDateRange] = React.useState(getDateRangeArray(yesterday(), yesterday()));

  function getDateRangeArray (startDate, endDate) {
    const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' });
    const [{ value: startMonth },,{ value: startDay },,{ value: startYear }] = dateTimeFormat.formatToParts(startDate);
    const startDayString = `${startYear}-${startMonth}-${startDay}`;
    const [{ value: endMonth },,{ value: endDay },,{ value: endYear }] = dateTimeFormat.formatToParts(endDate);
    const endDayString = `${endYear}-${endMonth}-${endDay}`;
    return [startDayString, endDayString];
  }

  function downloadCSV() {
    Notification["success"]({
      top: 80,
      title: "success",
      description: "exporting your csv report"
    });
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Provider
        store={createAdminStore({
            authProvider,
            dataProvider,
            history,
        })}
    >
    <DataProviderContext.Provider value={dataProvider}>
    <ThemeProvider theme={theme}>
      <Container className={classes.container}>
      <AppBar position="static" className={classes.appBar} color="default">
        <Toolbar className={classes.toolbar}>
          <Typography variant="h5" noWrap>
            Report
            <Tooltip className={classes.hint} title={(<p>Reports for "finished" days and periods should be available within 1-4 hours after the day is finished.<br/>Reports which include "today" are constantly being updated as follows:<br/>-"Today" reports are updated every 6 hours<br/>-"This week" and "This month" reports are updated every 12 hours (processed twice a day)<br/>-"This year" reports are processed once a day.</p>)}>
              <IconButton className={classes.hint}>
                <HelpIcon className={classes.hint}/>
              </IconButton>
            </Tooltip>
          </Typography>
          <Typography className={classes.title} variant="h5" noWrap>
          <DateRangePicker cleanable={false} ranges={[{
              label: 'Yesterday',
              value: [dateFns.addDays(new Date(), -1), dateFns.addDays(new Date(), -1)]
            }, {
              label: 'Last 2 days',
              value: [dateFns.addDays(new Date(), -2), dateFns.addDays(new Date(), -1)]
            }, {
              label: 'Last 7 days',
              value: [dateFns.subDays(new Date(), 6), new Date()]
            }]}
              disabledDate={date => dateFns.isAfter(date, yesterday())} defaultValue={[new Date(dateRange[0]), new Date(dateRange[1])]} onChange={value => {
              setDateRange(getDateRangeArray(value[0], value[1]));
            }}
          />
          </Typography>
        </Toolbar>
      </AppBar>
      <Tabs
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          value={value}
          onChange={handleChange}
          centered>
            <Tab label="Engagement Statistics" />
            <Tab label="Meetup Statistics" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <Container className={classes.container}>
          <Header className={classes.header}>
          Overview
          </Header>
          <Content className={classes.content}>
            <ReportsListView dateRange={dateRange} reportId={reportId} allField={allField}/>
          </Content>
          <Footer></Footer>
        </Container>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <BoothMeetupsView dateRange={dateRange} reportId={meetupId}/>
        </TabPanel>
      </Container>
    </ThemeProvider>
    </DataProviderContext.Provider>
    </Provider>
  );
}
