import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import styled from 'styled-components';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Grid, Tooltip } from '@material-ui/core';
import { Info, Autorenew } from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { Timeline, Avatar, Icon } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import CircleIndicator from './CircleIndicator';
import formatDashboardTime from '../../EventRealtimeDashboard/utils/formatDashboardTime';

const FAKE_HISTORY_DATA = [
  { user_token: "db92ae60-80d6-458b-86d5-be45663c5d5d", email: "regine@eventxtra.com", first_name: "Regine", last_name: "Cheung", first_action_at: 1606894901293, numbers: 1 },
  { user_token: "67bf9604-a6c4-42c1-bcfa-ca9878bc4f1a", email: "jillian.l@grid.com.sg", first_name: "Jillian", last_name: "Lim", first_action_at: 1606894249417, numbers: 1 },
  { user_token: "67bf9604-a6c4-42c1-bcfa-ca9878bc4f1a", email: "jillian.l@grid.com.sg", first_name: "Jillian", last_name: "Lim", first_action_at: 1606894242620, numbers: 1 },
  { user_token: "db92ae60-80d6-458b-86d5-be45663c5d5d", email: "regine@eventxtra.com", first_name: "Regine", last_name: "Cheung", first_action_at: 1606893650291, numbers: 1 }
];
const useStyles = makeStyles({
  card: {
    padding: '16px',
  },
  tooltip: {
    marginLeft: '8px',
  },
  info: {
    color: 'rgba(0,0,0,.65)',
  }
});

const Wrapper = styled.div``;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background: #fff;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
`;

const SubHeader = styled.span`
  margin: 0 8px;
`;

const Title = styled.h5`
`;

const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin: 16px 0;
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 50px;
  justify-content: center;
`;

const CardTitle = styled.h6`
  display:flex;
  align-items: center;
`;

const CardBox = styled.div`
  margin: 8px 0;
  border: 1px solid #666;
  height: 315px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

const BoxTitle = styled.div`
  font-size: 36px;
`;

const BoxNumber = styled.div`
  font-size: 60px;
`;

const BoxText = styled.div`
`;

const TimeLineBox = styled.div`
  margin: 16px;
  height: 299px;
  overflow-y: scroll;
`;

const VisiterRow = styled.div`
  margin-top: 4px;
  display: flex;
  align-items: center;
`;

const VisiterData = styled.div`
  margin-left: 8px;
`;

const ErrorBox = styled.div`
  margin: 100px auto;
  text-align: center;
  font-size: 24px;
  color: #666;
`;

const Notes = styled.div``;

const NoteTitle = styled.div`
  font-weight: bold;
`;

const NoteText = styled.div``;

const Hint = styled.span`
  font-size: 14px;
  padding: 12px 6px;
`;

const HINT = {
  ACTIVE_VISITORS: `“Active visitors” shows how many visitors entered your booth in the past 5 minutes.`,
  VISITORS_OF_THE_DAY: `"Visits" shows how many unique visitors entered your booth today. `,
  VISITORS_HISTORY: `"Visitors" shows the list of booth visitors of the day.`,
};

function BoothVisitors() {
  const { id: domain } = useParams();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [lastUpdatedTime, setLastUpdatedTime] = useState('');
  const [currentActiveUser, setCurrentActiveUser] = useState(0);

  useEffect(() => {
    fetchData();

    const timer = AutoUpdate();
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!loading) {
      setLastUpdatedTime(formatDashboardTime());
    }
  }, [loading]);

  function handleError(errMsg) {
    setError(errMsg);
    if (loading) {
      setLoading(false);
    }
  };

  function AutoUpdate() {
    setTimeout(() => {
      fetchData();
      AutoUpdate();
    }, 600000) // 10 min
  };

  function fetchData() {
    console.log('fetch data!');
    setLoading(true);
    Promise.all([
      fetchCurrentActiveUser(),
    ]).then(() => {
      console.log('fetch data done');
      setLoading(false);
    })
  }

  async function fetchCurrentActiveUser() {
    const boothId = 'virtual.2vanx.com';
    const { status, data } = await axios.get(`https://rongcloud-online-status.web.app/currentActiveUser?domain=${boothId}`);
    if (status === 200) {
      setCurrentActiveUser(data.currentCount);
    } else {
      setError("Get Current Active User Failed");
      setCurrentActiveUser(0);
    }
  }

  function handleRefresh() {
    console.log('refresh!!');
    fetchData();
  }

  console.log('latestVisitorsList', FAKE_HISTORY_DATA);

  return (
    <Wrapper>
      {loading && <CircleIndicator />}
      <Header>
        <Title>Booth Visitors</Title>
        <SubHeader>Last updated: {lastUpdatedTime}</SubHeader>
        <IconButton onClick={handleRefresh}><Autorenew /></IconButton>
      </Header>

      <Content>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Card className={classes.card}>
              <CardHeader>
                <CardTitle>
                  Active Visitors
                <Tooltip title={<Hint>{HINT.ACTIVE_VISITORS}</Hint>} className={classes.tooltip} arrow>
                    <Info className={classes.info} />
                  </Tooltip>
                </CardTitle>
              </CardHeader>
              <CardBox>
                <BoxTitle>
                  Last 5 minutes
                </BoxTitle>
                <BoxNumber>
                  {currentActiveUser}
                </BoxNumber>
                <BoxText>visitors entered your booth</BoxText>
              </CardBox>
            </Card>
          </Grid>
          <Grid item md={6} xs={12}>
            <Card className={classes.card}>
              <CardHeader>
                <CardTitle>
                  Visitors of the day
                <Tooltip title={<Hint>{HINT.VISITORS_OF_THE_DAY}</Hint>} className={classes.tooltip} arrow>
                    <Info className={classes.info} />
                  </Tooltip>
                </CardTitle>
              </CardHeader>
              <CardBox>
                <BoxTitle>
                  Until Now
                </BoxTitle>
                <BoxNumber>
                  {currentActiveUser}
                </BoxNumber>
                <BoxText>visitors entered your booth</BoxText>
              </CardBox>
            </Card>
          </Grid>
          {/* Login History */}
          <Grid item md={12} xs={12}>
            <Card className={classes.card}>
              <CardHeader>
                <CardTitle>
                  Visit History of the day
                <Tooltip title={<Hint>{HINT.VISITORS_HISTORY}</Hint>} className={classes.tooltip} arrow>
                    <Info className={classes.info} />
                  </Tooltip>
                </CardTitle>
              </CardHeader>
              <TimeLineBox>
                {FAKE_HISTORY_DATA?.length < 1 && error && <ErrorBox>{error}</ErrorBox>}
                {
                  FAKE_HISTORY_DATA?.length > 0 && (
                    <Timeline hover>
                      {
                        FAKE_HISTORY_DATA.map((row, idx) => (
                          <Timeline.Item key={`action_list_${idx}`}>
                            <div>{formatDashboardTime(row.first_action_at)}</div>
                            <VisiterRow>
                              <Avatar circle size="xs"> <Icon icon="user" /></Avatar>
                              <VisiterData>
                                <div>{row.first_name} {row.last_name}</div>
                                <div>Title | Company</div>
                              </VisiterData>
                            </VisiterRow>
                          </Timeline.Item>
                        ))
                      }
                    </Timeline>
                  )
                }
              </TimeLineBox>
            </Card>
          </Grid>
        </Grid>
      </Content>
      <Notes>
        <NoteTitle>Notes</NoteTitle>
        <NoteText>1. Data will be automatically updated each 10 minutes. You can also click the refresh button to load the up-to-date data.</NoteText>
        <NoteText>2. "Visitors of the day" and "Visit History of the day"  will be cleaned every midnight. You can check the previous data in Reports.</NoteText>
      </Notes>
    </Wrapper>
  );
}

export default BoothVisitors;

