import * as React from "react";
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, Loading, Error } from 'react-admin';
import { Container, Header, Content, Footer } from 'rsuite';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import { Message, Notification, DateRangePicker } from 'rsuite';
import CsvDownload from 'react-json-to-csv';
import dateFns from "date-fns";
import dataProvider from './data-providers';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    minHeight: 60,
    alignItems: 'flex-center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    alignSelf: 'flex-center',
    paddingLeft: theme.spacing(2),
  },
  container: {
    marginBottom: '40px',
  },
  header: {
    lineHeight: '60px',
  },
  content: {
    border: '0px solid black',
  },
  hint: {
    maxHeight: 20,
    maxWidth: 20,
  },
  table: {
    minWidth: 200,
  },
  downloadcsv: {
    float: 'right',
  },
  popper: {
    minWidth: 1000,
    minHeight: 500,
  },
}));

const EventBoothsWebinarView = (props) => {
  const reportId = props.match.params.id;
  const classes = useStyles();
  const [reports, setReports] = useState();
  const [loading, setLoading] = useState(true);
  const [matomoloading, setMatomoLoading] = useState(true);
  const [error, setError] = useState();
  const [open, setOpen] = useState(false);
  const yesterday = () => {
    var date = new Date();
    date.setDate(date.getDate() - 1);
    return date;
  };
  const [dateRange, setDateRange] = React.useState(getDateRangeArray(yesterday(), yesterday()));
  const [boothVisitors, setBoothVisitors] = useState();
  const [matomoBooths, setMatomoBooths] = useState();
  const [matomoVisitors, setMatomoVisitors] = useState([]);
  const [finalBoothVisitors, setFinalBoothVisitors] = useState();
  let count = 0;
  let matomoList = [];

  function getDateRangeArray(startDate, endDate) {
    const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' });
    const [{ value: startMonth }, , { value: startDay }, , { value: startYear }] = dateTimeFormat.formatToParts(startDate);
    const startDayString = `${startYear}-${startMonth}-${startDay}`;
    const [{ value: endMonth }, , { value: endDay }, , { value: endYear }] = dateTimeFormat.formatToParts(endDate);
    const endDayString = `${endYear}-${endMonth}-${endDay}`;
    return [startDayString, endDayString];
  }

  useEffect(() => {
  // This effect uses the `value` variable,
  // so it "depends on" `value`.
    
    // console.log(dateRange);
    var dateString;
    if (dateRange) {
        dateString = `${dateRange[0]},${dateRange[1]}`;
    } else {
        dateString = '2020-07-07,2020-07-30';
    }
    const payload = {
      pagination: { page: 1, perPage: 500 },
      sort: { field: 'id', order: 'ASC' },
      dateRange: dateString,
      reportId: reportId,
    };

    setBoothVisitors();
    setMatomoBooths();
    setMatomoVisitors([]);
    setFinalBoothVisitors();
    count = 0;
    matomoList = [];

    setLoading(true);
    // firebase realtime db
    dataProvider.getList('getEventEngagementPromises', payload)
      .then(({ data }) => {
        console.log(data);
        if (!data || !data.booths || data.booths.length <= 0) {
          setLoading(false);
        }
        setReports(data);
        let boothVisitors = [];
        data.booths.forEach(({ users }) => {
          boothVisitors = boothVisitors.concat(users);
        })

        setBoothVisitors(boothVisitors);
      })
      .catch(error => {
        setError(error);
        setReports([]);
        setLoading(false);
        // reject();
      })

      setMatomoLoading(true);
      // matomo booths
      dataProvider.getList('matomo/eventBooths', payload)
      .then(({ data }) => {
        if (!data || data.length <= 0) {
          setMatomoLoading(false);
        }
        setMatomoBooths(data);
      })
      .catch(error => {
        setError(error);
        setMatomoLoading(false);
        setMatomoBooths([]);
      })
  }, [dateRange, reportId]);

  // fetch visitors of every booth
  useEffect(() => {
    if(matomoBooths) {
      matomoBooths.forEach((row, idx) => {
        dataProvider.getList('matomo/eventBoothVisitorsByFilter', {
          pagination: { page: 1, perPage: 500 },
          sort: { field: 'username', order: 'ASC' },
          dateRange: `${dateRange[0]},${dateRange[1]}`,
          reportId: reportId,
          filterPattern: row.id,
       })
        .then(({ data }) => {
          const list = data.map(({id, email, visits}) => ({
            user_token: id,
            email,
            numbers: visits,
            booth_id: row.id,
            booth_name: row.label,
          }))
          count += 1;
          matomoList = matomoList.concat(list);
          console.log(`fetched ${count} of ${matomoBooths?.length} times`);
          if (count === matomoBooths?.length) {
            setMatomoVisitors(matomoList);
          }
        })
        .catch(error => {
          setError(error);
        })
      })
    }
  }, [matomoBooths])

  useEffect(() => {
    if (matomoVisitors.length > 0) {
      console.log('fetch end');
      if (!finalBoothVisitors && boothVisitors) {
        integrateFinalList();
        setMatomoLoading(false);
        setLoading(false);
      }
    }
  }, [matomoVisitors]);

  useEffect(() => {
    if (!finalBoothVisitors && matomoVisitors.length > 0) {
      integrateFinalList();
      setLoading(false);
    } else {
      // setLoading(false);
    }
  }, [boothVisitors]);

  function integrateFinalList() {
    const list = [ ...boothVisitors, ...matomoVisitors ];
    const finalList = {};
    list.forEach((item) => {
      const listId = `${item.booth_id.trim()}_${item.email.trim()}_`;
      if (!finalList[listId]) {
        finalList[listId] = item;
      }
    });
    setFinalBoothVisitors(Object.values(finalList));
  }

  if (error) return (
    <Message
      type="error"
      title="Error"
      description={
        <p>
          {error.message}
          <br />
          Please try again later, if still happened, please
          <a href="https://gist.github.com/angusluk/3417c09cfa65aba48b62b74dd8fc4bda"> contact us.</a>
        </p>
      }
    />
  );

  function showNotification(message){
    Notification["success"]({
      top: 80,
      title: "success",
      description: message
    });
  };

  return (
    <Container className={classes.container}>
      <Container className={classes.container}>
        <Header className={classes.header}>
          Booths test
        </Header>
        <DateRangePicker ranges={[{
          label: 'Yesterday',
          value: [dateFns.addDays(new Date(), -1), dateFns.addDays(new Date(), -1)]
        }, {
          label: 'Last 2 days',
          value: [dateFns.addDays(new Date(), -2), dateFns.addDays(new Date(), -1)]
        }, {
          label: 'Last 7 days',
          value: [dateFns.subDays(new Date(), 6), new Date()]
        }]}
          disabledDate={date => dateFns.isAfter(date, yesterday())} defaultValue={[new Date(dateRange[0]), new Date(dateRange[1])]} onChange={value => {
            setDateRange(getDateRangeArray(value[0], value[1]));
          }}
        />
        {
          loading === true && (
          <div className={classes.root}>
            <Skeleton />
            <Skeleton animation={false} />
            <Skeleton animation="wave" />
          </div>
          )
        }
        {
           !boothVisitors || boothVisitors.length <= 0 && (
            <Message
              type="info"
              title="Informational"
              description={
                <p>
                  <b>There is no data from firebase report.</b>
                </p>
              }
            />
          )
        }
        {
          matomoloading === true && (
          <div className={classes.root}>
            <Skeleton />
            <Skeleton animation={false} />
            <Skeleton animation="wave" />
          </div>
          )
        }
        {
          !matomoBooths || matomoBooths.length <= 0 && (
            <Message
              type="info"
              title="Informational"
              description={
                <p>
                  <b>There is no data from matomo report.</b>
                </p>
              }
            />
          )
        }
        <Content className={classes.content}>
        {
          finalBoothVisitors?.length > 0 && (
          <TableContainer component={Paper}>
            <div className={classes.downloadcsv} onClick={() => {showNotification("exporting your booths report")}}>
              <CsvDownload data={finalBoothVisitors} filename={`report_combined booth visitor list_${dateRange[0]}-${dateRange[1]}.csv`}>Export CSV</CsvDownload>
            </div>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>user_token</TableCell>
                  <TableCell>email</TableCell>
                  <TableCell>first_name</TableCell>
                  <TableCell>last_name</TableCell>
                  <TableCell>first_action_at</TableCell>
                  <TableCell>booth_id</TableCell>
                  <TableCell>booth_name</TableCell>
                  <TableCell>numbers</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {finalBoothVisitors?.map((row, idx) => (
                  <TableRow key={`tablerow_${idx}`}>
                    <TableCell>{row.user_token}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.first_name}</TableCell>
                    <TableCell>{row.last_name}</TableCell>
                    <TableCell>{row.first_action_at}</TableCell>
                    <TableCell>{row.booth_id}</TableCell>
                    <TableCell>{row.booth_name}</TableCell>
                    <TableCell>{row.numbers}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          )
        }
        </Content>
      </Container>
    </Container>
  )
};

EventBoothsWebinarView.propTypes = {
};


export default EventBoothsWebinarView;
