import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Card, Grid, Tooltip, IconButton } from '@material-ui/core';
import { Info, Autorenew } from '@material-ui/icons';
import moment from 'moment';
import axios from 'axios';
import ReactApexChart from 'react-apexcharts';
import CircleIndicator from './CircleIndicator';
import formatDashboardTime from '../utils/formatDashboardTime';


const useStyles = makeStyles({
  card: {
    padding: '16px',
  },
  chart: {
    width: '100%',
  },
  tooltip: {
    marginLeft: '8px',
  },
  info: {
    color: 'rgba(0,0,0,.65)',
  }
});

const Wrapper = styled.div`
  background: #fafafa;
  position: relative;
  margin: 16px 0;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
`;

const SubHeader = styled.span`
  margin: 0 8px;
`;

const Title = styled.h5`
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 16px;
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 50px;
  justify-content: center;
`;

const CardTitle = styled.h6`
  display:flex;
  align-items: center;
`;

const CardBox = styled.div`
  margin: 8px 0;
  border: 1px solid #666;
  height: 315px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

const BoxTitle = styled.div`
  font-size: 36px;
`;

const BoxNumber = styled.div`
  font-size: 60px;
`;

const BoxText = styled.div`
`;

const Notes = styled.div`
  padding: 16px;
`;

const NoteTitle = styled.div`
  font-weight: bold;
`;

const NoteText = styled.div``;

const Hint = styled.span`
  font-size: 14px;
`;

const HINT = {
  CURRENT_VISITORS: `“Active visitors” shows how many visitors stayed on the site in the past 5 minutes.`,
  OVER_TIME: 'Line chart of the number of active visitors in the past 24 hours.',
}

function ActiveVisitors() {
  const classes = useStyles();
  const { id } = useParams();
  const domain = id?.split('_')?.join('.');
  const [loading, setLoading] = useState(true);
  const [lastUpdatedTime, setLastUpdatedTime] = useState('');
  const [currentActiveUser, setCurrentActiveUser] = useState(0);
  const [historyActiveUser, setHistoryActiveUser] = useState({
    times: [],
    count: [],
  });
  const [error, setError] = React.useState();

  useEffect(() => {
    fetchData();

    const timer = AutoUpdate();
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!loading) {
      setLastUpdatedTime(formatDashboardTime());
    }
  }, [loading]);

  function AutoUpdate() {
    setTimeout(() => {
      fetchData();
      AutoUpdate();
    }, 600000) // 10 min
  };

  function fetchData() {
    console.log('fetch data!');
    setLoading(true);
    Promise.all([
      fetchCurrentActiveUser(),
      fetchHistoryActiveUser(),
    ]).then(() => {
      console.log('fetch data done');
      setLoading(false);
    })
  }

  async function fetchCurrentActiveUser() {
    const { status, data } = await axios.get(`https://rongcloud-online-status.web.app/currentActiveUser?domain=${domain}`);
    if (status === 200) {
      setCurrentActiveUser(data.currentCount);
    } else {
      setError("Get Current Active User Failed");
      setCurrentActiveUser(0);
    }
  }

  async function fetchHistoryActiveUser() {
    const { status, data } = await axios.get(`https://rongcloud-online-status.web.app/historyActiveUser?domain=${domain}`);
    if (status === 200) {
      const historyActiveUser = {
        times: [],
        count: [],
      };
      data.forEach(({ timestamp, count }) => {
        historyActiveUser.times.push(moment.unix(timestamp).format('LT'));
        historyActiveUser.count.push(count);
      })
      setHistoryActiveUser(historyActiveUser);
    } else {
      setError("Get History Active User Failed");
      setHistoryActiveUser();
    }
  }

  function handleRefresh() {
    console.log('refresh!!');
    fetchData();
  }

  return (
    <Wrapper>
      {loading && <CircleIndicator />}
      <Header>
        <Title>Active Visitors</Title>
        <SubHeader>Last updated: {lastUpdatedTime}</SubHeader>
        <IconButton onClick={handleRefresh}><Autorenew /></IconButton>
      </Header>
      {error && <p>{error}</p>}
      <Content>
        <Grid container spacing={2}>
          {/* Active Visitors */}
          <Grid item md={4} xs={12}>
            <Card className={classes.card}>
              <CardHeader>
                <CardTitle>
                  Current Visitors
                <Tooltip title={<Hint>{HINT.CURRENT_VISITORS}</Hint>} className={classes.tooltip} arrow>
                    <Info className={classes.info} />
                  </Tooltip>
                </CardTitle>
              </CardHeader>
              <CardBox>
                <BoxTitle>
                  Right Now
              </BoxTitle>
                <BoxNumber>
                  {currentActiveUser}
                </BoxNumber>
                <BoxText>active visitors on site</BoxText>
              </CardBox>
            </Card>
          </Grid>
          {/* Visitor Chart */}
          <Grid item md={8} xs={12}>
            <Card className={classes.card}>
              <CardHeader>
                <CardTitle>
                  Over Time
                  <Tooltip title={<Hint>{HINT.OVER_TIME}</Hint>} className={classes.tooltip} arrow>
                    <Info className={classes.info} />
                  </Tooltip>
                </CardTitle>
              </CardHeader>
              <CardBox>
                <ReactApexChart
                  className={classes.chart}
                  options={{
                    chart: {
                      zoom: {
                        enabled: false
                      },
                      toolbar: {
                        tools: {
                          download: false
                        }
                      },
                    },
                    markers: {
                      size: 5,
                    },
                    dataLabels: {
                      enabled: false
                    },
                    stroke: {
                      curve: 'straight'
                    },
                    grid: {
                      row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                      },
                    },
                    xaxis: {
                      categories: historyActiveUser.times,
                      hideOverlappingLabels: true,
                      tickAmount: 11,
                    }
                  }}
                  series={[{
                    name: 'Visitors',
                    data: historyActiveUser.count
                  }]}
                  type="area"
                  height={300}
                />
              </CardBox>
            </Card>
          </Grid>
        </Grid>
      </Content>
      <Notes>
        <NoteTitle>Notes</NoteTitle>
        <NoteText>1. Current visitors are counted every 5 minutes. The Over Time line chart are counted every 1 hour.</NoteText>
        <NoteText>2. Data will be automatically updated each 10 minutes. You can also click the refresh button to load the up-to-date data.</NoteText>
      </Notes>
    </Wrapper>
  );
}

export default ActiveVisitors;

