import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Grid, Tooltip, CircularProgress, Fade } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { Timeline, Avatar, Icon } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css';
import CircleIndicator from './CircleIndicator';
import loginVisitorsWorker from '../utils/loginVisitorsWorker';
import formatDashboardTime from '../utils/formatDashboardTime';
import CountUp from 'react-countup';
import firebaseDB from "../../firebaseStaging";

const useStyles = makeStyles({
  card: {
    padding: '16px',
  },
  tooltip: {
    marginLeft: '8px',
  },
  info: {
    color: 'rgba(0,0,0,.65)',
  }
});

const Wrapper = styled.div`
  background: #fafafa;
  position: relative;
  margin: 16px 0;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
`;

const SubHeader = styled.span`
  margin: 0 8px;
`;

const Title = styled.h5`
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 16px;
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 50px;
  justify-content: center;
`;

const CardTitle = styled.h6`
  display:flex;
  align-items: center;
`;

const CardBox = styled.div`
  margin: 8px 0;
  border: 1px solid #666;
  height: 315px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

const BoxTitle = styled.div`
  font-size: 36px;
`;

const BoxNumber = styled.div`
  font-size: 60px;
`;

const BoxText = styled.div`
`;

const TimeLineBox = styled.div`
  margin: 16px;
  height: 299px;
  overflow-y: scroll;
`;

const ErrorBox = styled.div`
  margin: 100px auto;
  text-align: center;
  font-size: 24px;
  color: #666;
`;

const Notes = styled.div`
  padding: 16px;
`;

const NoteTitle = styled.div`
  font-weight: bold;
`;

const NoteText = styled.div``;


const Hint = styled.span`
  font-size: 14px;
  padding: 12px 6px;
`;

const HINT = {
  LOGGED_IN_VISITORS: `"Unique Logged-In Visitors" shows how many unique visitors click the Access URL to login today.`,
  LOGIN_HISTORY: `"Login History" shows the list of logged-in visitors in real time.`,
}
var startCount = 0;
var runCountingAnimation = true;

function LoginVisitors() {
  const { id: domain } = useParams();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [counting, setCounting] = React.useState(false);
  const [loginCount, setLoginCount] = useState(0);
  const [latestVisitorsList, setLatestVisitorsList] = React.useState([]);
  const [error, setError] = React.useState();
  const [countingDuration, setCountingDuration] = useState(10);

  useEffect(() => {
    var connectedRef = firebaseDB.ref(".info/connected");
    connectedRef.on("value", function(snap) {
      if (snap.val() === true) {
        loginVisitorsWorker(domain, formatterCallback, handleError);
        // alert("connected");
      } else {
        loginVisitorsWorker(domain, formatterCallback, handleError, true);
        // alert("not connected");
      }
    });
  }, []);

  function handleError(errMsg) {
    setError(errMsg);
    if (loading) {
      setLoading(false);
    }
  };

  function formatterCallback({ loginNumber, latest_visitors_list, animate }) {
    if (loginNumber !== loginCount) {
      if (loading) {
        setLoading(false);
      }
      setLoginCount(loginNumber);
    }
    if ((latest_visitors_list && latest_visitors_list.length) !== latestVisitorsList.length) {
      if (loading) {
        setLoading(false);
      }
      setLatestVisitorsList(latest_visitors_list);
    }

    if (animate !== runCountingAnimation) {
      runCountingAnimation = animate;
    }
    if(animate === false) {
      startCount = loginNumber;
      setCountingDuration(0.1);
    } else {
      if (startCount !== loginNumber) {
        setCountingDuration(10);
      }
    }
  }

  return (
    <Wrapper>
      {loading && <CircleIndicator />}
      <Header>
        <Title>Logged-In Visitors of the day</Title>
        <SubHeader>Updated in real-time</SubHeader>
      </Header>
      <Content>
        {loading && <CircleIndicator />}
        <Grid container spacing={2}>
          {/* Login Visitors */}
          <Grid item md={4} xs={12}>
            <Card className={classes.card}>
              <CardHeader>
                <CardTitle>
                  Unique Logged-In Visitors
                <Tooltip title={<Hint>{HINT.LOGGED_IN_VISITORS}</Hint>} className={classes.tooltip} arrow>
                    <Info className={classes.info} />
                  </Tooltip>
                </CardTitle>
              </CardHeader>
              <CardBox>
                <BoxTitle>
                  Until Now
                </BoxTitle>
                <BoxNumber>
                <Fade in={counting}
                  style={{
                    transitionDelay: loading ? '800ms' : '0ms',
                  }}
                  unmountOnExit>
                  <CircularProgress />
                </Fade>
                  <CountUp start={startCount} end={loginCount} duration={countingDuration} preserveValue={true} onStart={({ pauseResume }) => setCounting(true)} onEnd={({ pauseResume }) => setCounting(false)}>

                  </CountUp>
                </BoxNumber>
                <BoxText>logged-in visitors on site</BoxText>
              </CardBox>
            </Card>
          </Grid>
          {/* Login History */}
          <Grid item md={8} xs={12}>
            <Card className={classes.card}>
              <CardHeader>
                <CardTitle>
                  Login History
                <Tooltip title={<Hint>{HINT.LOGIN_HISTORY}</Hint>} className={classes.tooltip} arrow>
                    <Info className={classes.info} />
                  </Tooltip>
                </CardTitle>
              </CardHeader>
              <TimeLineBox>
                {latestVisitorsList?.length < 1 && error && <ErrorBox>{error}</ErrorBox>}
                {
                  latestVisitorsList?.length > 0 && (
                    <Timeline hover>
                      {
                        latestVisitorsList.map((row, idx) => (
                          <Timeline.Item key={`action_list_${idx}`}>
                            <p>{formatDashboardTime(row.last_changed)}</p>
                            <p><Avatar circle size="xs"> <Icon icon="user" /></Avatar> {row.email} logged in</p>
                          </Timeline.Item>
                        ))
                      }
                    </Timeline>
                  )
                }
              </TimeLineBox>
            </Card>
          </Grid>
        </Grid>
      </Content>
      <Notes>
        <NoteTitle>Notes</NoteTitle>
        <NoteText>1. Logged-In Visitors is updated in real time. </NoteText>
        <NoteText>2. Data will be cleaned every midnight. You can check the previous data in Reports</NoteText>
      </Notes>
    </Wrapper>
  );
}

export default LoginVisitors;

