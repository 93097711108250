import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import EventReport from './EventReport';
import BoothReport from './BoothReport';
import EventReport817 from './EventReport817';
import BoothReport817 from './BoothReport817';
import EventReport902 from './EventReport902';
import BoothReport902 from './BoothReport902';
import EventReportRealtime from './EventReportRealtime';
import EventReportRealtimeStaging from './EventReportRealtimeStaging';
import BoothReportRealtime from './BoothReportRealtime';
import EventRealtimeDashboard from './EventRealtimeDashboard';
import EventRealtimeDashboardStaging from './EventRealtimeDashboardStaging';
import BoothDashboard from './BoothDashboard';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Route } from 'react-router-dom';
import CombineVisitorReport from './CombineVisitorReport';
import CombineBoothReport from './CombineBoothReport';
import combineWebinarReport from './CombineWebinarReport';

ReactDOM.render(
  <React.StrictMode>
  <BrowserRouter>
    <Route path="/report/:id" component={BoothReport}></Route>
    <Route path="/event/:id" component={EventReport}></Route>
    <Route path="/817/event/:id" component={EventReport817}></Route>
    <Route path="/817/report/:id" component={BoothReport817}></Route>
    <Route path="/902/event/:id" component={EventReport902}></Route>
    <Route path="/902/report/:id" component={BoothReport902}></Route>
    <Route path="/reed/event/:id" component={EventReport902}></Route>
    <Route path="/reed/report/:id" component={BoothReport902}></Route>
    <Route path="/rt/event/:id" component={EventReportRealtime}></Route>
    <Route path="/rt/event_staging/:id" component={EventReportRealtimeStaging}></Route>
    <Route path="/rt/report/:id" component={BoothReportRealtime}></Route>
    <Route path="/rt/dashboard/:id" component={EventRealtimeDashboard}></Route>
    <Route path="/rt/newdashboard/:id" component={EventRealtimeDashboard}></Route>
    <Route path="/combineVisitorReport/:id" component={CombineVisitorReport}></Route>
    <Route path="/combineBoothReport/:id" component={CombineBoothReport}></Route>
    <Route path="/combineWebinarReport/:id" component={combineWebinarReport}></Route>
    <Route path="/booth/dashboard/:id" component={BoothDashboard}></Route>
    <Route path="/rt/dashboard_staging/:id" component={EventRealtimeDashboardStaging}></Route>
  </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
