import * as React from "react";
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, Loading, Error } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import { Message, Notification, Header } from 'rsuite';
import CsvDownload from 'react-json-to-csv';
// import { Container, , Content, Footer, Sidebar } from 'rsuite';

const useStyles = makeStyles({
  table: {
    minWidth: 200,
  },
  downloadcsv: {
    float: 'right',
  },
  container: {
    marginBottom: '40px',
  },
  header: {
    lineHeight: '60px',
  },
});


const ReportsListView = ({dateRange, reportId}) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [reports, setReports] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
  // This effect uses the `value` variable,
  // so it "depends on" `value`.
    setLoading(true);
    // console.log(dateRange);
    var dateString;
    if (dateRange) {
        dateString = `${dateRange[0]},${dateRange[1]}`;
    } else {
        dateString = '2020-07-07,2020-07-30';
    }
    const payload = {
      pagination: { page: 1, perPage: 500 },
      sort: { field: 'id', order: 'ASC' },
      dateRange: dateString,
      reportId: reportId,
    };
    dataProvider.getList('boothReport', payload)
      .then(({ data }) => {
        // console.log(data);
        setReports(data);
        setLoading(false);
        // resolve();
      })
      .catch(error => {
        setError(error);
        setLoading(false);
        setReports([]);
        // reject();
      })
  }, [dateRange, reportId]);

  // if (loading) return <Loading loadingPrimary="loading Reposts" loadingSecondary=""/>;
  if (loading) return (
    <div className={classes.root}>
      <Skeleton />
      <Skeleton animation={false} />
      <Skeleton animation="wave" />
    </div>
  );
  if (error) return (
    <Message
      type="error"
      title="Error"
      description={
        <p>
          {error.message}
          <br />
          Please try again later, if still happened, please
          <a href="https://gist.github.com/angusluk/3417c09cfa65aba48b62b74dd8fc4bda"> contact us.</a>
        </p>
      }
    />
  );
  if (!reports || reports === undefined || reports.success === false) return (
    <Message
      type="info"
      title="Informational"
      description={
        <p>
          <b>Hmm... the report is not ready yet.</b>
          <br />
          Data for this report should become available within 1-4 hours when processing completes.
          <br/>
          If it doesn't, please
           <a href="https://gist.github.com/angusluk/3417c09cfa65aba48b62b74dd8fc4bda"> Contact us.</a>
        </p>
      }
    />
  );
  if(reports.length <= 0) return (
    <Message
      type="info"
      title="Informational"
      description={
        <p>
          <b>There is no data for this report.</b>
        </p>
      }
    />
  );

  function showNotification(message){
    Notification["success"]({
      top: 80,
      title: "success",
      description: message
    });
  };

  return (
    <>
    {
      reports.overview && reports.overview.length > 0 && (
      <div className={classes.container}>
      <TableContainer component={Paper}>
        <div className={classes.downloadcsv} onClick={() => {showNotification("exporting your booth report")}}>
          <CsvDownload data={reports.overview} filename={`report_overview_${dateRange[0]}-${dateRange[1]}.csv`}>Export CSV</CsvDownload>
        </div>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Label</TableCell>
              <TableCell align="right">Number</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {reports.overview.map(row => (
            <TableRow key={row.id} hover>
              <TableCell component="th" scope="row">
                {row.label}
              </TableCell>
              <TableCell align="right">{row.number}</TableCell>
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
      )
    }
    {
      reports.banner && reports.banner.length > 0 && (
      <div className={classes.container}>
      <Header className={classes.header}>
        Banner Clicks Distribution
      </Header>
      <TableContainer component={Paper}>
        <div className={classes.downloadcsv} onClick={() => {showNotification("exporting your booth bannner report")}}>
          <CsvDownload data={reports.banner} filename={`report_banner_${dateRange[0]}-${dateRange[1]}.csv`}>Export CSV</CsvDownload>
        </div>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Label</TableCell>
              <TableCell align="right">Number of Click</TableCell>
              <TableCell align="right">Unique Visitor</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {reports.banner.map(row => (
            <TableRow key={row.id} hover>
              <TableCell component="th" scope="row">
                {row.label}
              </TableCell>
              <TableCell align="right">{row.number}</TableCell>
              <TableCell align="right">{row.visitors}</TableCell>
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
      )
    }
    {
      reports.tabs && reports.tabs.length > 0 && (
      <div className={classes.container}>
      <Header className={classes.header}>
        Tab Clicks Distribution
      </Header>
      <TableContainer component={Paper}>
        <div className={classes.downloadcsv} onClick={() => {showNotification("exporting your booth tabs report")}}>
          <CsvDownload data={reports.tabs} filename={`report_tabs_${dateRange[0]}-${dateRange[1]}.csv`}>Export CSV</CsvDownload>
        </div>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Label</TableCell>
              <TableCell align="right">Number of Click</TableCell>
              <TableCell align="right">Unique Visitor</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {reports.tabs.map(row => (
            <TableRow key={row.id} hover>
              <TableCell component="th" scope="row">
                {row.label}
              </TableCell>
              <TableCell align="right">{row.number}</TableCell>
              <TableCell align="right">{row.visitors}</TableCell>
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
      )
    }
    {
      reports.files && reports.files.length > 0 && (
      <div className={classes.container}>
      <Header className={classes.header}>
        Files View and Bookmark
      </Header>
      <TableContainer component={Paper}>
        <div className={classes.downloadcsv} onClick={() => {showNotification("exporting your booth files report")}}>
          <CsvDownload data={reports.files} filename={`report_files_${dateRange[0]}-${dateRange[1]}.csv`}>Export CSV</CsvDownload>
        </div>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Label</TableCell>
              <TableCell align="right">Number of Views</TableCell>
              <TableCell align="right">Number of Bookmark</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {reports.files.map(row => (
            <TableRow key={row.id} hover>
              <TableCell component="th" scope="row">
                {row.label}
              </TableCell>
              <TableCell align="right">{row.views}</TableCell>
              <TableCell align="right">{row.bookmarks}</TableCell>
            </TableRow>
          ))}
          </TableBody>
        </Table>
      </TableContainer>
      </div>
      )
    }
    </>
  )
};

ReportsListView.propTypes = {
  dateRange: PropTypes.array.isRequired,
  reportId: PropTypes.string.isRequired,
};

export default ReportsListView;

