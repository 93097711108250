import * as React from "react";
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, Loading, Error } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import { Message, Notification, Container, Header, Content, Footer } from 'rsuite';
import CsvDownload from 'react-json-to-csv';
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Fade from "@material-ui/core/Fade";
import PerBoothVisitorsView from './PerBoothVisitorsView';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'

const useStyles = makeStyles({
  table: {
    minWidth: 200,
  },
  downloadcsv: {
    float: 'right',
  },
  popper: {
    minWidth: 600,
    minHeight: 400,
  },
  container: {
    marginBottom: '40px',
    marginRight: '1%',
    marginLeft: '1%',
  },
  header: {
    lineHeight: '60px',
  },
  content: {
    border: '0px solid black',
  },
});

const MeetupUniqueVisitorsView = ({dateRange, reportId, eventId, salesId, mode, resource}) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [booths, setBooths] = useState();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // console.log(dataProvider);
    var dateString;
    if (dateRange) {
      var endDay = dateRange[1];
      let { userAgent } = window.navigator;
        if (userAgent.includes('Safari')) {
            if (typeof endDay === 'string') {
                endDay = endDay.replace(/-/g, '/');
                dateString = `${new Date(dateRange[0]).getTime()},${new Date(endDay+ " 23:59:59").getTime()}`;
            } else {
              dateString = `${new Date(dateRange[0]).getTime()},${new Date(dateRange[1]+ " 23:59:59").getTime()}`;
            }
        } else {
          dateString = `${new Date(dateRange[0]).getTime()},${new Date(dateRange[1]+ " 23:59:59").getTime()}`;
        }
    } else {
        dateString = '';
    }
    setLoading(true);
    const payload = {
       pagination: { page: 1, perPage: 500 },
       sort: { field: 'username', order: 'ASC' },
       dateRange: dateString,
       eventId: eventId,
       reportId: reportId,
       salesId: salesId,
       mode: mode,
    };

    dataProvider.getMeetupList(resource, payload)
      .then(({ data }) => {
        // console.log(data);
        setData(data);
        setLoading(false);
        // resolve();
      })
      .catch(error => {
        setError(error);
        setLoading(false);
        setData(undefined);
        // reject();
      })
    }, [dateRange, reportId]);

  // if (loading) return <Loading loadingPrimary="loading Visitors" loadingSecondary=""/>;
  if (loading) return (
    <div className={classes.root}>
      <Skeleton />
      <Skeleton animation={false} />
      <Skeleton animation="wave" />
    </div>
  );
  if (error) return (
    <Message
      type="error"
      title="Error"
      description={
        <p>
          {error.message}
          <br />
          Please try again later, if still happened, please
          <a href="https://gist.github.com/angusluk/3417c09cfa65aba48b62b74dd8fc4bda"> contact us.</a>
        </p>
      }
    />
  );
  if (!data || data === undefined || data.success === false) return (
    <Message
      type="info"
      title="Informational"
      description={
        <p>
          <b>Hmm... the booths report is not ready yet.</b>
          <br />
          Data for this report should become available within 1-4 hours when processing completes.
          <br/>
          If it doesn't, please
           <a href="https://gist.github.com/angusluk/3417c09cfa65aba48b62b74dd8fc4bda"> Contact us.</a>
        </p>
      }
    />
  );
  if(data.list.length <= 0) return (
    <Message
      type="info"
      title="Informational"
      description={
        <p>
          <b>There is no data for this report.</b>
        </p>
      }
    />
  );

  function showNotification(message){
    Notification["success"]({
      top: 80,
      title: "success",
      description: message
    });
  };

  const handleClick = (event) => {
    setOpen(open ? false : event.currentTarget);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  return (
    <>
    <Container className={classes.container}>
          <Header className={classes.header}>
          Unique Visitors List ({data.list.length})
          </Header>
          <Content className={classes.content}>
    <TableContainer component={Paper}>
      <div className={classes.downloadcsv} onClick={() => {showNotification("exporting your booths report")}}>
        <CsvDownload data={data.list} filename={`report_meetup_${mode}_unique visitor list_${dateRange[0]}-${dateRange[1]}.csv`}>Export CSV</CsvDownload>
      </div>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell>email</TableCell>
            <TableCell align="right">Number of Scheduled</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.list.map(row => (
            <TableRow key={row.email} hover>
              <TableCell align="left">{row.first}</TableCell>
              <TableCell align="left">{row.last}</TableCell>
              <TableCell component="th" scope="row">
                {row.email}
              </TableCell>
              <TableCell align="right">{row.count}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Content>
          <Footer></Footer>
        </Container>
    </>
  )
};

MeetupUniqueVisitorsView.propTypes = {
  dateRange: PropTypes.array.isRequired,
  mode: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  reportId: PropTypes.string,
  eventId: PropTypes.string,
  salesId: PropTypes.string,
};


export default MeetupUniqueVisitorsView;
