import firebase from "firebase";

let firebaseConfig = {
  apiKey: "AIzaSyAs3kSaFu_-N3qDg9cXYnOJq9b_rgT8eyI",
  authDomain: "test-realtime-db-606fe.firebaseapp.com",
  databaseURL: "https://test-realtime-db-606fe.firebaseio.com",
  projectId: "test-realtime-db-606fe",
  storageBucket: "test-realtime-db-606fe.appspot.com",
  messagingSenderId: "209371421286",
  appId: "1:209371421286:web:683df7a8188311e2836ba7"
};

var staging = firebase.initializeApp(firebaseConfig, "test-realtime-db");
firebase.auth(staging).signInAnonymously();
export default firebase.database(staging);
