// import WebWorker from "react-webworker"
import React, { useEffect, useState } from 'react';
import firebaseDB from "../../firebaseStaging";
import worker_script from './worker';
import socketIOClient from "socket.io-client";

const myWorker = new Worker(worker_script);// relative path to the source file, not the public URL
const ENDPOINT = "wss://vexpo-websockets.herokuapp.com/";

let loginNumber = 0;
let total_visitors = [];
let total_visitors_list = [];
let latest_visitors_list = [];
let current_doamin = "";
let animate = true;
let current_date = "";

let auto_Callback = false;
let auto_save = false;

function sleep(milliseconds) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}

function onDataAdded(item, websocket = false) {
  if (websocket === true) {
    let key = item.data.key;
    let childData = item.data.item;
    var list = [];
    myWorker.postMessage(['receiving', key, childData]);
  } else {
    if (item.exists() === false) {
      console.log("No Data yet");
      return ;
    }
    let key = item.key;
    let childData = item.val();
    var list = [];
    myWorker.postMessage(['receiving', key, childData]);
  }
};

function organizerReportRowFormat(key, childData) {
  return {user_token: key, email: childData.user_email, last_changed: childData.last_changed};
}

function AutoCallback(callback) {
  setTimeout(() => {
    animate = true;
    callback({ loginNumber, latest_visitors_list, animate });
    AutoCallback(callback);
  }, 3000);
};

function AutoSave() {
  setTimeout(() => {
    if (latest_visitors_list && latest_visitors_list.length) {
      let object = {
        'running_date': current_date,
        'domain': current_doamin,
        'loginNumber': loginNumber,
        'latest_visitors_list': latest_visitors_list,
        'total_visitors': total_visitors,
        'last_update_time': latest_visitors_list[0].last_changed || 0
      }
      localStorage.setItem('ocms_dashboard', JSON.stringify(object));
    }
    AutoSave();
  }, 30000);
}

function fetchCacheDashboard() {
  try {
    return JSON.parse(localStorage.getItem('ocms_dashboard') || {});
  } catch (e) {
    return {};
  }
}

function loginVisitorsWorker(domain, callback, setError, websocket = false) {
  const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: '2-digit', day: '2-digit' });
  const [{ value: startMonth },,{ value: startDay },,{ value: startYear }] = dateTimeFormat.formatToParts(new Date());
  const startDayString = `${startYear}-${startMonth}-${startDay}`;
  current_date = startDayString;
  var startDate = new Date(startDayString+"T00:00:00.000+08:00");
  // var startDate = new Date("2021-01-06"+"T00:00:00.000+08:00");
  // current_date = "2021-01-06";

  var startQueryDatetime = startDate.getTime();
  let dashboard_data = fetchCacheDashboard();
  if (dashboard_data.domain === domain) {
    console.log('same domain');
    if(dashboard_data.running_date === current_date && dashboard_data.last_update_time > startQueryDatetime) {
      startQueryDatetime = dashboard_data.last_update_time;

      loginNumber = dashboard_data.loginNumber;
      latest_visitors_list = dashboard_data.latest_visitors_list;
      total_visitors = dashboard_data.total_visitors;

      myWorker.postMessage(['init', loginNumber, latest_visitors_list, total_visitors]);

      animate = false;
      callback({ loginNumber, latest_visitors_list, animate });
    } else {
      loginNumber = 1;
      latest_visitors_list = [];
      total_visitors = [];
      current_doamin = domain;
      let object = {
        'running_date': current_date,
        'domain': current_doamin,
        'loginNumber': loginNumber,
        'latest_visitors_list': latest_visitors_list,
        'total_visitors': total_visitors,
        'last_update_time': startQueryDatetime || 0
      }
      localStorage.setItem('ocms_dashboard', JSON.stringify(object));
    }
  } else {
    console.log('no cache');
    loginNumber = 1;
    latest_visitors_list = [];
    total_visitors = [];
    current_doamin = domain;
    let object = {
      'running_date': current_date,
      'domain': current_doamin,
      'loginNumber': loginNumber,
      'latest_visitors_list': latest_visitors_list,
      'total_visitors': total_visitors,
      'last_update_time': startQueryDatetime || 0
    }
    localStorage.setItem('ocms_dashboard', JSON.stringify(object));
  }
  if (auto_save === false) {
    auto_save = true;
    AutoSave();
  }
  current_doamin = domain;

  if (websocket) {
    console.log('using websocket');
    let socketServer = socketIOClient(ENDPOINT, { query: `domain=${domain}&startQueryDatetime=${startQueryDatetime}`, transports: ['websocket'] });
    socketServer.on('records', function (socket) {
      if (socket.error) {
        setError("No Data yet");
        loginNumber = 0;
      } else {
        onDataAdded(socket, true);
      }
    });

    animate = true;
    callback({ loginNumber, latest_visitors_list, animate });
    if (auto_Callback === false) {
      auto_Callback = true;
      AutoCallback(callback);
    }
  } else {
    console.log('not using websocket');
    let dbRef = firebaseDB.ref("/online_status/"+domain).orderByChild('last_changed').startAt(startQueryDatetime);
    let checkDBRes = firebaseDB.ref("/online_status/"+domain).orderByChild('last_changed').startAt(startQueryDatetime).limitToLast(1);
    checkDBRes.once('value', records => {
      if (!records.exists() ) {
        setError("No Data yet");
        loginNumber = 0;
      }
      dbRef.on("child_added", onDataAdded);
      dbRef.on("child_changed", onDataAdded);

      animate = true;
      callback({ loginNumber, latest_visitors_list, animate });
      if (auto_Callback === false) {
        auto_Callback = true;
        AutoCallback(callback);
      }
    }, error => {
      console.log(error);
      // setError("The read failed: " + error.code);
    });
  }

  myWorker.onmessage = (m) => {
  	loginNumber = m.data[0];
  	latest_visitors_list = m.data[1];
    total_visitors = m.data[2];
  };
}

export default loginVisitorsWorker;
