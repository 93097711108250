import * as React from "react";
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, Loading, Error } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import { Message, Notification } from 'rsuite';
import { Container, Header, Content, Footer } from 'rsuite';
import CsvDownload from 'react-json-to-csv';
import formatTime from '../../utils/formatTime';

import Button from "@material-ui/core/Button";

const useStyles = makeStyles({
  table: {
    minWidth: 200,
  },
  downloadcsv: {
    float: 'right',
    color: 'black',
  },
});

const EventVisitorsView = ({dateRange, reportId}) => {
  const classes = useStyles();
  const dataProvider = useDataProvider();
  const [visitors, setVisitors] = useState();
  const [visitorsNumber, setVisitorsNumber] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  var totalActions = 0;
  var totalScreenTime = 0;

  const [random, setRandom] = useState(Math.random());
  const reload = () => {
    const tempRandom = Math.random();
    if (tempRandom === random) {
      return reload();
    }
    setRandom(Math.random());
  };

  useEffect(() => {
    var dateString;
    if (dateRange) {
        dateString = `${dateRange[0]},${dateRange[1]}`;
    } else {
        dateString = '2020-07-07,2020-07-30';
    }
    setLoading(true);
    const payload = {
       pagination: { page: 1, perPage: 500 },
       sort: { field: 'username', order: 'ASC' },
       dateRange: dateString,
       reportId: reportId,
    };

    dataProvider.getList('getEventVisitorsPromisesDataLess', payload)
      .then(({ data }) => {
        // console.log(data);
        setVisitorsNumber(data.totalNumber);
        setVisitors(data.lessData);
        setLoading(false);
        // resolve();
      })
      .catch(error => {
        setError(error);
        setLoading(false);
        setVisitors([]);
        // reject();
      })
  }, [dateRange, reportId, random]);

  // if (loading) return <Loading loadingPrimary="loading Visitors" loadingSecondary=""/>;
  if (loading) return (
    <div className={classes.root}>
      <Skeleton />
      <Skeleton animation={false} />
      <Skeleton animation="wave" />
    </div>
  );
  if (error) return (
    <Message
      type="error"
      title="Error"
      description={
        <div>
          <p>
            {error.message}
            <br />
            Please try again. If the problem still persists, you can
            <a href="https://gist.github.com/angusluk/3417c09cfa65aba48b62b74dd8fc4bda"> contact us.</a>
          </p>
          <br />
          <Button variant="contained" onClick={() => reload()}>
            Retry
          </Button>
        </div>
      }
    />
  );
  if (!visitors || visitors === undefined || visitors.success === false) return (
    <Message
      type="info"
      title="Informational"
      description={
        <p>
          <b>Hmm... the visitors report is not ready yet.</b>
          <br />
          Data for this report should become available within 1-4 hours when processing completes.
          <br/>
          If it doesn't, please
           <a href="https://gist.github.com/angusluk/3417c09cfa65aba48b62b74dd8fc4bda"> Contact us.</a>
        </p>
      }
    />
  );
  if(visitors.length <= 0) return (
    <Message
      type="info"
      title="Informational"
      description={
        <p>
          <b>There is no data for this report.</b>
        </p>
      }
    />
  );

  function showNotification(message){
    Notification["success"]({
      top: 80,
      title: "success",
      description: message
    });
  };

  return (
    <>
    <Container className={classes.container}>
          <Header className={classes.header}>
          Unique Visitors List ({visitorsNumber})
          </Header>
          <Content className={classes.content}>
    <TableContainer component={Paper}>
      <div className={classes.downloadcsv} onClick={() => {showNotification("exporting your visitors report")}}>
        <a style={{color: "black"}} href={`${process.env.REACT_APP_VEXPO_REPORT_ENDPOINT}getEventVisitorsPromisesCSV?dateRange=${dateRange[0]},${dateRange[1]}&reportId=${reportId}`}><button>Export CSV</button></a>
      </div>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>E-mail</TableCell>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell align="right">Actions</TableCell>
            <TableCell align="right">First Action At</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {visitors.map(row => (
            <TableRow key={row.user_token} hover>
              <TableCell component="th" scope="row">
                {row.email}
              </TableCell>
              <TableCell align="left" id={row.first_name+row.user_token}>{row.first_name}</TableCell>
              <TableCell align="left" id={row.last_name+row.user_token}>{row.last_name}</TableCell>
              <TableCell align="right" id={totalActions += row.numbers}>{row.numbers}</TableCell>
              <TableCell align="right" id={row.first_action_at}>{formatTime(row.first_action_at)}</TableCell>
            </TableRow>
          ))}
          <TableRow key={'total'} hover>
            <TableCell component="th" scope="row">
            </TableCell>
            <TableCell/>
            <TableCell align="right">Will only show 100 records, to see full data please export to CSV.</TableCell>
            <TableCell/>
            <TableCell/>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
    </Content>
          <Footer></Footer>
        </Container>
    </>
  )
};

EventVisitorsView.propTypes = {
  dateRange: PropTypes.array.isRequired,
  reportId: PropTypes.string.isRequired,
};


export default EventVisitorsView;
