import firebase from "firebase";

let firebaseConfig = {
  apiKey: "AIzaSyBxmZGLHj8lkmiwFk8Ru0cHLVyvu15HmeA",
  authDomain: "vexpo-report.firebaseapp.com",
  databaseURL: "https://vexpo-report.firebaseio.com",
  projectId: "vexpo-report",
  storageBucket: "vexpo-report.appspot.com",
  messagingSenderId: "622135541250",
  appId: "1:622135541250:web:8fe3ae43b23251f497711b"
};

var production = firebase.initializeApp(firebaseConfig);
firebase.auth(production).signInAnonymously();
export default firebase.database();
